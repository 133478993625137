import { ProtocolSelect, useFormContext } from "@enzymefinance/hook-form";
import { Alert, Badge, ErrorMessage, RadioGroup, Skeleton } from "@enzymefinance/ui";
import { isTruthy } from "@enzymefinance/utils";
import { adapter, refine } from "@enzymefinance/validation";
import { useGlobals } from "components/providers/GlobalsProvider";
import type { ReactNode } from "react";
import { useCallback, useMemo } from "react";
import { useAdapterOptions } from "utils/hooks/useAdapterOptions";
import { useKnownAdapterLists } from "utils/hooks/useKnownAdapterLists";
import { z } from "zod";

import { toAddress } from "@enzymefinance/environment";
import { NumberInput } from "@enzymefinance/hook-form";
import { Policies } from "@enzymefinance/sdk/Configuration";
import { Constants } from "@enzymefinance/sdk/Utils";
import { isAddressEqual, zeroAddress } from "viem";
import { InlineLink } from "../../../routing/Link";
import { VaultConfigFieldName } from "../VaultConfig";
import type { AllowedAdaptersPolicyAlertProps, AllowedAdaptersPolicySettings } from "../VaultConfigSettingsTypes";
import type {
  VaultConfig,
  VaultConfigDisplayProps,
  VaultConfigDisplaySubgraphProps,
  VaultConfigFormFieldsProps,
} from "../VaultConfigTypes";
import { VaultConfigContext, VaultConfigPolicyListOption, VaultConfigType } from "../VaultConfigTypes";
import { InlinePolicyAdapterList, PolicyAdapterList } from "./PolicyAdapterList";

export const allowedAdaptersPolicySchema = z
  .object({
    items: z.array(adapter()),
    listId: z.string().optional(),
    selectedOptionType: z.nativeEnum(VaultConfigPolicyListOption).optional(),
  })
  .refine(
    (value) => {
      return value.selectedOptionType !== VaultConfigPolicyListOption.CUSTOM_LIST || value.items.length > 0;
    },
    {
      message: 'Please specify some values or choose "Disallow All"',
      path: ["items"],
    },
  )
  .transform(
    refine(
      (value) => {
        return (
          value.selectedOptionType === VaultConfigPolicyListOption.EMPTY_LIST ||
          value.selectedOptionType === VaultConfigPolicyListOption.CUSTOM_LIST ||
          value.listId !== ""
        );
      },
      {
        message: 'Please specify a value or choose "Disallow All"',
        path: ["listId"],
      },
    ),
  )
  .optional();

interface AllowedAdaptersPolicyFormFieldsValues {
  [VaultConfigFieldName.ALLOWED_ADAPTERS_POLICY]?: Partial<AllowedAdaptersPolicySettings>;
}

function allowedAdaptersPolicyFormFields({
  listOptions,
}: VaultConfigFormFieldsProps<VaultConfigType.ALLOWED_ADAPTERS_POLICY>) {
  const { getFieldState, setValue, watch } = useFormContext<AllowedAdaptersPolicyFormFieldsValues>();
  const defaultOptions = useAdapterOptions();
  const knownAdapterLists = useKnownAdapterLists();
  const { error } = getFieldState(VaultConfigFieldName.ALLOWED_ADAPTERS_POLICY);
  const [value] = watch([VaultConfigFieldName.ALLOWED_ADAPTERS_POLICY]) as [
    AllowedAdaptersPolicyFormFieldsValues[VaultConfigFieldName.ALLOWED_ADAPTERS_POLICY],
  ];

  // If we add items to the list, we should not display the provided options
  // If we remove items from the list, we should only display the provided options
  const options = useMemo(
    () =>
      listOptions?.action === "add"
        ? defaultOptions.filter(
            (defaultOption) =>
              !(
                isAddressEqual(defaultOption.id, zeroAddress) ||
                listOptions.options.items.find((option) => isAddressEqual(defaultOption.id, option.id))
              ),
          )
        : listOptions?.action === "remove"
          ? listOptions.options.items.filter((option) => !isAddressEqual(option.id, zeroAddress))
          : defaultOptions.filter((option) => !isAddressEqual(option.id, zeroAddress)),
    [defaultOptions, listOptions],
  );

  const handleSelectListOption = useCallback(
    ({ optionType, value: newValue }: ListSelectOption) => {
      const knownListData =
        newValue === knownAdapterLists?.adapters.id
          ? knownAdapterLists.adapters
          : newValue === knownAdapterLists?.noSlippageAdapters.id
            ? knownAdapterLists.noSlippageAdapters
            : undefined;

      const newFieldValue: AllowedAdaptersPolicySettings = {
        listId: optionType === VaultConfigPolicyListOption.DELEGATED ? newValue : "",
        selectedOptionType: optionType,
        items: newValue === VaultConfigPolicyListOption.DELEGATED ? knownListData?.items ?? value?.items ?? [] : [],
      };

      setValue(VaultConfigFieldName.ALLOWED_ADAPTERS_POLICY, newFieldValue, { shouldValidate: true });
    },
    [setValue, value?.items, knownAdapterLists],
  );

  const protocolSelectElement = useMemo(
    () => (
      <div className="space-y-4">
        <ProtocolSelect
          isExpandable={true}
          isMulti={true}
          label={allowedAdaptersPolicy.label}
          labelHidden={true}
          name={`${VaultConfigFieldName.ALLOWED_ADAPTERS_POLICY}.items`}
          options={options}
        />
      </div>
    ),
    [options],
  );

  const listIdElement = useMemo(
    () => (
      <div className="space-y-4">
        <NumberInput
          label={allowedAdaptersPolicy.label}
          labelHidden={true}
          name={`${VaultConfigFieldName.ALLOWED_ADAPTERS_POLICY}.listId`}
        />
      </div>
    ),
    [],
  );

  const listSelectOptions: ListSelectOption[] = useMemo(
    () =>
      [knownAdapterLists?.adapters, knownAdapterLists?.noSlippageAdapters]
        .map((adapterListItem) =>
          adapterListItem?.id
            ? {
                description: <InlinePolicyAdapterList adapters={adapterListItem.items} />,
                label: `Use council-maintained list - ${adapterListItem.description}`,
                optionType: VaultConfigPolicyListOption.DELEGATED,
                value: adapterListItem.id,
              }
            : undefined,
        )
        .concat([
          {
            value: VaultConfigPolicyListOption.CUSTOM_LIST,
            label: "Specify your own adapter list",
            description:
              value?.selectedOptionType === VaultConfigPolicyListOption.CUSTOM_LIST ? protocolSelectElement : <></>,
            optionType: VaultConfigPolicyListOption.CUSTOM_LIST,
          },
          {
            value: VaultConfigPolicyListOption.CUSTOM_LIST_ID,
            label: "Specify your own adapter list ID (advanced)",
            description:
              value?.selectedOptionType === VaultConfigPolicyListOption.CUSTOM_LIST_ID ? listIdElement : <></>,
            optionType: VaultConfigPolicyListOption.CUSTOM_LIST_ID,
          },
          {
            value: VaultConfigPolicyListOption.EMPTY_LIST,
            label: "Disallow all adapters",
            description: <></>,
            optionType: VaultConfigPolicyListOption.EMPTY_LIST,
          },
        ])
        .filter(isTruthy),
    [
      listIdElement,
      knownAdapterLists?.adapters,
      knownAdapterLists?.noSlippageAdapters,
      protocolSelectElement,
      value?.selectedOptionType,
    ],
  );

  const selectedListOption = useMemo(
    () =>
      listSelectOptions.find((option) => option.value === value?.listId) ??
      listSelectOptions.find((option) => option.optionType === value?.selectedOptionType) ??
      listSelectOptions.find((option) => option.value === VaultConfigPolicyListOption.CUSTOM_LIST),
    [listSelectOptions, value?.listId, value?.selectedOptionType],
  );

  if (!knownAdapterLists) {
    return <Skeleton className="h-4 w-full" />;
  }

  if (listOptions?.action) {
    // If this FormFields component is used from PolicyEditList, render just the component that handles the list items
    return protocolSelectElement;
  }

  return (
    <div className="space-y-4">
      <RadioGroup<ListSelectOption>
        id={VaultConfigFieldName.ALLOWED_ADAPTERS_POLICY}
        value={selectedListOption}
        onChange={handleSelectListOption}
        label="Use an existing council-maintained list of adapters or specify your own"
        optionRenderer={(option) => (
          <div className="space-y-2">
            <p className="text-heading-content text-sm font-medium">{option.label}</p>
            {option.description}
          </div>
        )}
        options={listSelectOptions}
      />
      {typeof error?.message === "string" ? <ErrorMessage>{error.message}</ErrorMessage> : null}
    </div>
  );
}

function allowedAdaptersPolicyDisplay({ settings }: VaultConfigDisplayProps<VaultConfigType.ALLOWED_ADAPTERS_POLICY>) {
  const knownAdapterLists = useKnownAdapterLists({
    skip: settings.selectedOptionType !== VaultConfigPolicyListOption.DELEGATED,
  });

  let listDescription: string | undefined;

  if (knownAdapterLists && settings.selectedOptionType === VaultConfigPolicyListOption.DELEGATED) {
    if (settings.listId === knownAdapterLists.adapters.id) {
      listDescription = knownAdapterLists.adapters.description;
    } else if (settings.listId === knownAdapterLists.noSlippageAdapters.id) {
      listDescription = knownAdapterLists.noSlippageAdapters.description;
    }
  }

  if (settings.selectedOptionType === VaultConfigPolicyListOption.CUSTOM_LIST_ID) {
    listDescription = "Custom list ID (advanced)";
  }

  return (
    <PolicyAdapterList
      addresses={settings.items.map((setting) => setting.id)}
      isDelegated={settings.selectedOptionType === VaultConfigPolicyListOption.DELEGATED}
      listDescription={listDescription}
      listId={
        settings.selectedOptionType === VaultConfigPolicyListOption.CUSTOM_LIST_ID ||
        settings.selectedOptionType === VaultConfigPolicyListOption.DELEGATED
          ? settings.listId
          : undefined
      }
    />
  );
}

function allowedAdaptersPolicyDisplaySubgraph({
  settings,
}: VaultConfigDisplaySubgraphProps<VaultConfigType.ALLOWED_ADAPTERS_POLICY>) {
  const { environment } = useGlobals();

  // Assume there can be only delegated list
  const listId = settings.addressLists[0]?.id;
  const isDelegated = listId
    ? [environment.knownAddressLists.adapters, environment.knownAddressLists.noSlippageAdapters].includes(listId)
    : false;

  const addresses = settings.addressLists.flatMap((list) => list.items);

  return (
    <PolicyAdapterList
      isDelegated={isDelegated}
      addresses={addresses}
      listDescription={settings.addressLists[0]?.description ?? undefined}
    />
  );
}

function allowedAdaptersPolicyAlert({ enabled, value }: AllowedAdaptersPolicyAlertProps) {
  const knownAdapterLists = useKnownAdapterLists({
    skip: !(value?.selectedOptionType === VaultConfigPolicyListOption.DELEGATED),
  });

  if (!enabled || value === undefined) {
    return null;
  }

  const { items, listId, selectedOptionType } = value;

  if (selectedOptionType === VaultConfigPolicyListOption.DELEGATED && listId === knownAdapterLists?.adapters.id) {
    return null;
  }

  if (
    selectedOptionType === VaultConfigPolicyListOption.CUSTOM_LIST &&
    items.some(
      (policy) => policy.name.toLowerCase().includes("paraswap") || policy.name.toLowerCase().includes("1inch"),
    )
  ) {
    return null;
  }

  if (selectedOptionType === VaultConfigPolicyListOption.CUSTOM_LIST_ID) {
    return (
      <div className="px-2 sm:px-0">
        <Alert appearance="warning">
          <p>
            You have chosen to specify a custom list ID. You are solely responsible for ensuring that the specified list
            ID matches your intended policy settings.
          </p>
        </Alert>
      </div>
    );
  }

  // If user has a list without ParaSwap/1inch or chosen Disallow All, display warning
  return (
    <div className="px-2 sm:px-0">
      <Alert appearance="error">
        <p>
          You have not added the ParaSwap or 1inch Adapters and you will not be able to make simple trades with your
          vault. Please consider adding the ParaSwap or 1inch Adapters to the list of allowed adapters.
        </p>
      </Alert>
    </div>
  );
}

export const allowedAdaptersPolicy: VaultConfig<VaultConfigType.ALLOWED_ADAPTERS_POLICY> = {
  address: (contracts) => contracts.AllowedAdaptersPolicy,
  disableable: false,
  display: allowedAdaptersPolicyDisplay,
  displaySubgraph: allowedAdaptersPolicyDisplaySubgraph,
  editable: false,
  encode: (settings, encodeArgs) => {
    const unique = [...new Set(settings.items.map((item) => toAddress(item.id)))];

    // List already exists -> Point to that list ID
    if (
      (settings.selectedOptionType === VaultConfigPolicyListOption.CUSTOM_LIST_ID ||
        settings.selectedOptionType === VaultConfigPolicyListOption.DELEGATED) &&
      settings.listId
    ) {
      return Policies.AllowedAdapters.encodeSettings({
        existingListIds: [BigInt(settings.listId)],
        newListsArgs: [],
      });
    }

    if (
      encodeArgs?.context === VaultConfigContext.RECONFIGURATION ||
      encodeArgs?.context === VaultConfigContext.MIGRATION
    ) {
      const previousSettingsSet = new Set(
        encodeArgs.previousSettings?.items.map((item) => item.id.toLowerCase()) ?? [],
      );

      if (
        encodeArgs.previousSettings?.listId &&
        previousSettingsSet.size === unique.length &&
        unique.every((item) => previousSettingsSet.has(item)) &&
        // Where a vault was delegating to a council-maintained list and now "detaches" but keeps the same adapters,
        // we should create a copy of the list and not reference it. This condition covers that edge case
        !(encodeArgs.previousSettings.selectedOptionType === VaultConfigPolicyListOption.DELEGATED)
      ) {
        // New and old values contain the same items => reuse the list ID

        return Policies.AllowedAdapters.encodeSettings({
          existingListIds: [BigInt(encodeArgs.previousSettings.listId)],
          newListsArgs: [],
        });
      }
    }

    const args = Policies.AllowedAdapters.encodeSettings({
      existingListIds: [],
      newListsArgs: [
        {
          initialItems: unique,
          updateType: Constants.AddressListUpdateType.RemoveOnly,
        },
      ],
    });

    return args;
  },
  fetch: async () => undefined,
  formInitialValues: { selectedOptionType: undefined, items: [], listId: "" },
  formFields: allowedAdaptersPolicyFormFields,
  label: "Limit Adapters To A Specified List",
  managerDescription: (
    <div className="space-y-4">
      <p>
        Restricts the protocols with which a vault can interact to trade, lend, borrow, supply liquidity, et cetera.
      </p>
      <p>
        Turn on{" "}
        <InlineLink to="https://specs.enzyme.finance/topics/policies#allowedadapterspolicy" appearance="link">
          this policy
        </InlineLink>{" "}
        and select protocol adapters with which you would like to interact.
      </p>
      <p>
        Alternatively you can delegate to a list of adapters vetted and maintained by the Enzyme Council. The vault will
        benefit from any changes to these lists.
      </p>
      <p>
        <strong>
          Note that if you enable this policy but disallow all adapters, your vault will not be able to use any DeFi
          integrations. Adding new adapters to this list is only possible on a full vault reconfiguration.
        </strong>
      </p>
      <Badge appearance="warning">Semi-permanent Setting</Badge>
    </div>
  ),
  type: VaultConfigType.ALLOWED_ADAPTERS_POLICY,
  validationSchema: allowedAdaptersPolicySchema,
  alert: allowedAdaptersPolicyAlert,
};

interface ListSelectOption {
  description: ReactNode;
  label: string;
  optionType: VaultConfigPolicyListOption;
  value: string;
}
