import type { Address } from "@enzymefinance/environment";
import { Network, isAddress, networks, toAddress } from "@enzymefinance/environment";
import { parseUnits } from "@enzymefinance/ethereum-ui";
import { Form, SubmitButton, useForm, useUpdateField } from "@enzymefinance/hook-form";
import { BrokenBulb } from "@enzymefinance/icons";
import type { Viem } from "@enzymefinance/sdk/Utils";
import { Alert, Button, ErrorMessage, Icon, Modal, NumberDisplay, SectionHeading } from "@enzymefinance/ui";
import { address as addressSchema, safeParse } from "@enzymefinance/validation";
import { url } from "@enzymefinance/validation";
import { useMachine } from "@xstate/react";
import { useWallet } from "components/connection/Connection.js";
import { useGlobals } from "components/providers/GlobalsProvider";
import { TxDataDetails } from "components/transactions/FunctionDetails";
import { GasRelayerOptions } from "components/transactions/GasRelayerOptions";
import { TransactionGasOptions } from "components/transactions/TransactionGasOptions";
import type { MachineSend, MachineState, TxData } from "components/transactions/TransactionModalMachine";
import { Machine } from "components/transactions/TransactionModalMachine";
import { useVaultDetailsQuery } from "queries/core";
import type { ReactElement } from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useToggle } from "react-use";
import { debug, env } from "utils/config";
import { decodeTransactionData } from "utils/functions";
import { useBaseFee } from "utils/hooks/useBaseFee";
import { useCountdown } from "utils/hooks/useCountdown";
import { useGasRelayerBalance } from "utils/hooks/useGasRelayerBalance";
import { gasRelayerDataSchema, useGasRelayerData } from "utils/hooks/useGasRelayerData";
import { useGasRelayerEnabled } from "utils/hooks/useGasRelayerEnabled";
import { useIsGnosisSafeWallet } from "utils/hooks/useIsGnosisSafeWallet";
import { useIsRelayableCall } from "utils/hooks/useIsRelayableCall";
import { useMaxPriorityFee } from "utils/hooks/useMaxPriorityFee";
import { useTokenBalance } from "utils/hooks/useTokenBalance";
import { type Abi, type ContractFunctionName, type EncodeFunctionDataParameters, isAddressEqual } from "viem";
import { encodeFunctionData, zeroAddress } from "viem";
import { usePublicClient, useWalletClient } from "wagmi";
import { z } from "zod";
import { gasRelayerMaxAmount } from "../../utils/constants.js";
import { useNetwork } from "../providers/NetworkProvider.js";
import { TenderlyTransactionLink } from "./TenderlyTransactionLink";
import { TransactionDetails } from "./TransactionDetails";
import { useTransactionManager } from "./manager/TransactionManager";

const gasRelayer: Record<Network, string> = {
  [Network.ARBITRUM]: env.gsn1RelayerArbitrum,
  [Network.ETHEREUM]: env.gsn1RelayerMainnet,
  [Network.POLYGON]: env.gsn1RelayerMatic,
};

export type StartSdkFunction = (
  tx: Viem.PopulatedTransaction<any, any>,
  address: Address,
  vaultProxy?: Address,
) => void;

export function useTransactionModal() {
  const { address: accountAddress } = useWallet();
  const { data: walletClient } = useWalletClient();

  const { network } = useNetwork();
  const client = usePublicClient({ chainId: network });
  const [, add] = useTransactionManager();

  const [state, send, interpreter] = useMachine(Machine, {
    actions: {
      onSent: (context) => {
        const transaction = context.sentTransaction;

        if (transaction?.data && transaction.nonce) {
          add({
            data: transaction.data,
            from: transaction.from,
            hash: transaction.hash,
            isGasRelayer: !!context.useGasRelayer,
            nonce: transaction.nonce,
            to: transaction.to ?? zeroAddress,
          });
        }
      },
    },

    context: {
      client,
      walletClient,
      accountAddress,
    },
  });

  useEffect(() => {
    send({ walletClient, accountAddress, type: "AUTH" });
  }, [send, accountAddress, walletClient?.account]);

  const startTxData = useCallback(
    (txDataFn: (originAddress?: Address) => Promise<TxData> | TxData, address: Address, vaultProxy?: Address) => {
      send({
        network,
        submittedAccountAddress: address,
        txDataFn,
        type: "START",
        vaultProxy,
      });
    },
    [network],
  );

  const start = useCallback(
    <
      TAbi extends Abi = Abi,
      TFunctionName extends ContractFunctionName<TAbi, "payable" | "nonpayable"> = ContractFunctionName<
        TAbi,
        "payable" | "nonpayable"
      >,
    >(
      tx: Viem.PopulatedTransaction<TAbi, TFunctionName>,
      address: Address,
      vaultProxy?: Address,
    ) => {
      // Convert populated transaction to TxDataFn
      const txDataFn = (originAddress?: Address) => {
        return {
          data: encodeFunctionData<TAbi, TFunctionName>(
            tx.params as unknown as EncodeFunctionDataParameters<TAbi, TFunctionName>, // TODO: shouldn't be needed to use as
          ),
          from: originAddress ?? address,
          to: tx.params.address,
          value: tx.params.value ?? 0n,
        };
      };

      startTxData(txDataFn, address, vaultProxy);
    },

    [network, startTxData],
  );

  return { interpreter, send, startTxData, start, state };
}

export function defaultIsOpen(state: MachineState) {
  return !(state.matches("idle") || state.matches("sent") || state.matches("cancelled"));
}

interface TransactionModalProps {
  state: MachineState;
  send: MachineSend;
  bare?: boolean;
  title?: string;
  isOpen?: boolean;
  children?: ReactElement;
  timer?: number;
}

export function TransactionModal({
  bare = false,
  children,
  isOpen,
  state,
  send,
  title = "Review transaction",
  timer,
}: TransactionModalProps) {
  const close = useCallback(() => send("CANCEL"), [send]);
  const form = <TransactionModalForm state={state} send={send} timer={timer} />;
  const content = children ? (
    <div className="space-y-12">
      {children}
      {form}
    </div>
  ) : (
    form
  );

  return bare ? (
    content
  ) : (
    <Modal isOpen={isOpen ?? defaultIsOpen(state)} dismiss={close} title={title}>
      {content}
    </Modal>
  );
}

const formSchemaBase = z.object({
  expectedGasRelayerCost: z.bigint(),
  gasRelayerAvailableBalance: z.bigint().optional(),
  gasRelayerData: gasRelayerDataSchema.optional(),
  nativeTokenSymbol: z.string(),
  paymasterAddress: addressSchema({ allowZeroAddress: false }).optional(),
  gasRelayerEndpoint: url(),
  topUpGasRelayer: z.boolean(),
  useGasRelayer: z.boolean(),
});

const formSchema = formSchemaBase.transform((value, ctx) => {
  if (value.useGasRelayer && (value.gasRelayerAvailableBalance ?? 0n) < value.expectedGasRelayerCost) {
    ctx.addIssue({
      message: `The gas relayer does not have enough ${value.nativeTokenSymbol} for this transaction.`,
      path: ["gasRelayerAvailableBalance"],
      fatal: true,
      code: z.ZodIssueCode.custom,
    });
  }

  return value;
});

interface TransactionModalFormProps {
  state: MachineState;
  send: MachineSend;
  timer?: number;
}

function TransactionModalForm({ state, send, timer }: TransactionModalFormProps) {
  const vaultDetailsQuery = useVaultDetailsQuery({
    skip: !state.context.vaultProxy,
    variables: state.context.vaultProxy ? { id: state.context.vaultProxy.toLowerCase() } : undefined,
  });
  const vaultDetails = useMemo(
    () => (state.context.vaultProxy ? vaultDetailsQuery.data : undefined),
    [vaultDetailsQuery.data, state.context.vaultProxy],
  );
  const { network, client } = useNetwork();
  const { environment } = useGlobals();
  const { data: gasRelayerData, isLoading: isGasRelayerDataLoading } = useGasRelayerData(network);
  const comptrollerProxy = vaultDetails?.vault?.comptroller.id
    ? toAddress(vaultDetails.vault.comptroller.id)
    : undefined;
  const { data: gasRelayerBalance, isLoading: isGasRelayerBalanceLoading } = useGasRelayerBalance(
    client,
    comptrollerProxy,
  );
  const { data: gasRelayerEnabled, isLoading: isGasRelayerEnabledLoading } = useGasRelayerEnabled(
    client,
    comptrollerProxy,
  );

  const vaultProxy = state.context.vaultProxy ? toAddress(state.context.vaultProxy) : undefined;

  const { nativeTokenWrapper } = environment.namedTokens;

  const { data: wrapperBalance, isLoading: isWrapperBalanceLoading } = useTokenBalance(client, {
    account: vaultProxy,
    token: nativeTokenWrapper.id,
  });

  const abort = useCallback(() => send("ABORT"), [send]);
  const cancel = useCallback(() => send("CANCEL"), [send]);
  const retry = useCallback(() => send("RETRY"), [send]);

  const [showDetails, toggleDetails] = useToggle(false);
  const counter = useCountdown(timer);

  const baseFeeQuery = useBaseFee(client);

  const maxPriorityFeeQuery = useMaxPriorityFee(client);

  const { gasLimit, accountAddress, txData } = state.context;

  const { data: isGnosisSafeWallet } = useIsGnosisSafeWallet({
    address: accountAddress ? toAddress(accountAddress) : undefined,
    network,
  });

  const { chain } = useWallet();

  const isNetworkSupported = network === chain?.id;
  const networkLabel = networks[network].label;

  const getExpectedGasCost = useCallback(
    (baseValue?: bigint, priorityValue?: bigint) =>
      typeof gasLimit === "bigint" &&
      typeof baseValue === "bigint" &&
      typeof priorityValue === "bigint" &&
      typeof baseValue === "bigint" &&
      baseValue > 0n
        ? (priorityValue + baseValue) * gasLimit
        : undefined,
    [gasLimit],
  );

  const functionName = txData?.data ? decodeTransactionData(txData.data)?.fragment.name : undefined;

  const gasRelayerAllowed = useIsRelayableCall({
    args: txData?.data ? decodeTransactionData(txData.data)?.inputs : undefined,
    contractAddress: txData ? txData.to : undefined,
    functionName,
    vaultProxy: vaultDetails?.vault?.id,
  });

  // If true, this hides the gas relayer information completely. Used for depositor actions.
  const hideGasRelayer =
    functionName === undefined ||
    [
      "approve",
      "buySharesOnBehalf",
      "buyShares",
      "exchangeEthAndBuyShares",
      "exchangeErc20AndBuyShares",
      "redeemSharesForSpecificAssets",
      "redeemSharesInKind",
    ].includes(functionName);

  const useGasRelayer = useMemo(() => {
    return gasRelayerAllowed && !!gasRelayerEnabled && !isGnosisSafeWallet;
  }, [gasRelayerAllowed, gasRelayerEnabled, isGnosisSafeWallet]);

  const gasRelayerEndpoint = gasRelayer[network];
  const gasRelayerMaxAmountBigInt = parseUnits(gasRelayerMaxAmount[network], nativeTokenWrapper.decimals);

  const paymasterAddress = useMemo(
    () =>
      isAddress(vaultDetails?.vault?.comptroller.gasRelayer?.id)
        ? vaultDetails?.vault?.comptroller.gasRelayer?.id
        : undefined,
    [vaultDetails?.vault?.comptroller.gasRelayer?.id],
  );

  const baseFee = useMemo(() => baseFeeQuery.data ?? 0n, [baseFeeQuery.data]);

  const priorityFee = useMemo(() => maxPriorityFeeQuery.data?.standard ?? 0n, [maxPriorityFeeQuery.data?.standard]);

  const form = useForm({
    defaultValues: {
      expectedGasRelayerCost: 0n,
      gasRelayerAvailableBalance: undefined,
      gasRelayerData: undefined,
      gasRelayerEndpoint,
      nativeTokenSymbol: environment.network.currency.nativeToken.symbol,
      paymasterAddress: undefined,
      topUpGasRelayer: false,
      useGasRelayer: undefined,
    },
    onSubmit: (values) => {
      send({
        gasRelayerData: values.gasRelayerData,
        gasRelayerEndpoint: values.gasRelayerEndpoint,
        originAddress:
          Boolean(values.useGasRelayer) && isAddress(values.gasRelayerData?.relayWorkerAddress)
            ? values.gasRelayerData?.relayWorkerAddress
            : undefined,
        paymasterAddress: values.paymasterAddress,
        topUpGasRelayer: values.topUpGasRelayer,
        type: "SUBMIT",
        useGasRelayer: values.useGasRelayer,
      });
    },
    schema: formSchema,
  });

  const expectedGasRelayerCost = useMemo(() => {
    return gasLimit && gasRelayerData?.minGasPrice ? (gasRelayerData.minGasPrice * gasLimit * 125n) / 100n : 0n;
  }, [gasLimit, gasRelayerData?.minGasPrice]);

  const [useGasRelayerWatched, expectedGasRelayerCostWatched, topUpGasRelayerWatched] = form.watch([
    "useGasRelayer",
    "expectedGasRelayerCost",
    "topUpGasRelayer",
  ]);

  const useGasRelayerParsed = useMemo(
    () => safeParse(formSchemaBase.shape.useGasRelayer, useGasRelayerWatched),
    [useGasRelayerWatched],
  );

  const expectedGasRelayerCostParsed = useMemo(
    () => safeParse(formSchemaBase.shape.expectedGasRelayerCost, expectedGasRelayerCostWatched),
    [expectedGasRelayerCostWatched],
  );

  const topUpGasRelayerParsed = useMemo(
    () => safeParse(formSchemaBase.shape.topUpGasRelayer, topUpGasRelayerWatched),
    [topUpGasRelayerWatched],
  );

  const expectedGasCost = useMemo(
    () => getExpectedGasCost(baseFee, priorityFee),
    [baseFee, priorityFee, getExpectedGasCost],
  );

  const expectedGasOptionsLoading = useGasRelayerParsed
    ? (baseFeeQuery.isLoading && baseFeeQuery.isFetching) ||
      (maxPriorityFeeQuery.isLoading && maxPriorityFeeQuery.isFetching)
    : !expectedGasCost;

  const isLoadingData =
    (isGasRelayerEnabledLoading && comptrollerProxy !== undefined) ||
    isGasRelayerDataLoading ||
    maxPriorityFeeQuery.isLoading ||
    (isWrapperBalanceLoading && vaultProxy !== undefined) ||
    vaultDetailsQuery.loading ||
    baseFeeQuery.isLoading ||
    expectedGasOptionsLoading ||
    (isGasRelayerBalanceLoading && comptrollerProxy !== undefined);

  // The gas relayer tops-up before sending the tx, so if a vault has wrapped native token it can send tx with higher gas cost than the current balance
  const gasRelayerAvailableBalance = useMemo(() => {
    if (!(wrapperBalance && gasRelayerBalance)) {
      return gasRelayerBalance;
    }

    if (!topUpGasRelayerParsed) {
      return gasRelayerBalance;
    }

    // Gas relayer can't provide more than max, unless its balance is already higher
    if (gasRelayerBalance + wrapperBalance > gasRelayerMaxAmountBigInt) {
      return gasRelayerBalance > gasRelayerMaxAmountBigInt ? gasRelayerBalance : gasRelayerMaxAmountBigInt;
    }

    return gasRelayerBalance + wrapperBalance;
  }, [wrapperBalance, gasRelayerBalance, topUpGasRelayerParsed, gasRelayerMaxAmountBigInt]);

  // The gas relayer can only be topped up if the vault can fill it up completely.
  // We therefore only enable top up if vault holds enough wrapped native token to cover tx cost + available room
  const canTopUpGasRelayer = useMemo(() => {
    // Gas relayer can only be topped up once every 24 hours, so we only top it up if it's less than 20% full
    const topUpThreshold = gasRelayerMaxAmountBigInt / 5n;

    if ((gasRelayerBalance ?? 0n) <= topUpThreshold) {
      const gasRelayerRoom = gasRelayerMaxAmountBigInt - (gasRelayerBalance ?? 0n);
      const requiredBalance = gasRelayerRoom + expectedGasRelayerCost;

      return wrapperBalance !== undefined && wrapperBalance >= requiredBalance;
    }

    return false;
  }, [gasRelayerMaxAmountBigInt, gasRelayerBalance, expectedGasRelayerCost, wrapperBalance]);

  useUpdateField(form, "useGasRelayer", useGasRelayer, true);

  useUpdateField(form, "gasRelayerEndpoint", gasRelayerEndpoint, true);

  useUpdateField(form, "paymasterAddress", paymasterAddress, true);

  useUpdateField(form, "gasRelayerAvailableBalance", gasRelayerAvailableBalance ?? undefined, true);

  useUpdateField(form, "expectedGasRelayerCost", expectedGasRelayerCost, true);

  useUpdateField(form, "gasRelayerData", gasRelayerData ?? undefined, true);

  const isAbortable = state.matches("submitted.validating") || state.matches("submitted.delayed");
  const isError = state.matches("error");
  const isSubmitting = state.matches("submitted");
  const isEstimating = state.matches("estimating");

  const functionSummary = useMemo(() => {
    return txData ? <TxDataDetails txData={txData} /> : null;
  }, [txData]);

  return (
    <Form form={form}>
      {showDetails ? (
        <>
          <Modal.Body>
            {txData ? (
              <TransactionDetails
                comptrollerProxy={comptrollerProxy}
                from={state.context.submittedAccountAddress}
                network={network}
                txData={txData}
                vaultProxy={state.context.vaultProxy}
                vaultName={vaultDetails?.vault?.name}
              />
            ) : (
              <p>Missing transaction data</p>
            )}
          </Modal.Body>
          <Modal.Actions>
            <Button onClick={toggleDetails} appearance="secondary">
              Back
            </Button>
          </Modal.Actions>
        </>
      ) : (
        <>
          <Modal.Body className="space-y-8 px-2">
            {isError ? (
              <>
                <div className="flex items-center space-x-8">
                  <Icon className="h-24 w-auto" icon={BrokenBulb} size={8} />
                  <div className="flex flex-col justify-center space-y-1">
                    <SectionHeading.Title className="text-error">{state.context.error?.title}</SectionHeading.Title>
                    <p className="text-base font-normal">{state.context.error?.message}</p>
                  </div>
                </div>
                {functionSummary}
              </>
            ) : (
              <>
                {functionSummary}
                <div className="space-y-4 divide-y divide-gray-300 dark:divide-gray-700">
                  <TransactionGasOptions
                    error={useGasRelayerParsed ? baseFeeQuery.isError || maxPriorityFeeQuery.isError : undefined}
                    expectedGasCost={useGasRelayerParsed ? expectedGasRelayerCostParsed : expectedGasCost}
                    loading={expectedGasOptionsLoading}
                  />
                  {gasRelayerEnabled && !hideGasRelayer ? (
                    isGnosisSafeWallet ? (
                      <p className="pt-4">The Gas Relayer can not be used with a Gnosis Safe wallet.</p>
                    ) : (
                      <div className="pt-4">
                        {gasRelayerAllowed &&
                        useGasRelayerParsed !== undefined &&
                        topUpGasRelayerParsed !== undefined ? (
                          <GasRelayerOptions
                            onToggleClick={() =>
                              form.setValue("useGasRelayer", !useGasRelayerParsed, { shouldValidate: true })
                            }
                            useGasRelayer={useGasRelayerParsed}
                            gasRelayerBalance={gasRelayerBalance ?? 0n}
                            canTopUpGasRelayer={canTopUpGasRelayer}
                            vaultProxy={vaultDetails?.vault?.id}
                          />
                        ) : (
                          <p>The Gas Relayer can not be used for this transaction</p>
                        )}
                      </div>
                    )
                  ) : null}
                </div>
              </>
            )}

            <div className="mt-4 space-y-4">
              {typeof form.formState.errors.gasRelayerAvailableBalance?.message === "string" ? (
                <ErrorMessage>{form.formState.errors.gasRelayerAvailableBalance.message}</ErrorMessage>
              ) : null}

              {isNetworkSupported ? null : (
                <Alert title="Switch Network" appearance="warning">
                  Please switch to {networkLabel} to interact with Enzyme.
                </Alert>
              )}

              {typeof timer === "number" ? (
                <Alert className="space-y-2">
                  <p>
                    Trades that go through a ParaSwapPool time out if they are not mined within 2 minutes. We recommend
                    using fast gas for this transaction.
                  </p>
                  <p className="text-sm">
                    {typeof counter === "number" && counter > 0 ? (
                      <>
                        <NumberDisplay
                          className="tabular-nums"
                          appearance="simple"
                          numberFormat={{ unit: "second" }}
                          value={counter}
                        />{" "}
                        remaining
                      </>
                    ) : (
                      <>Timeout expired</>
                    )}
                  </p>
                </Alert>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Actions>
            {isError ? (
              <>
                <Button
                  onClick={retry}
                  disabled={
                    counter === 0 ||
                    (!!state.context.accountAddress &&
                      !!state.context.submittedAccountAddress &&
                      !isAddressEqual(state.context.accountAddress, state.context.submittedAccountAddress))
                  }
                >
                  Retry
                </Button>

                <Button onClick={cancel} appearance="secondary">
                  Cancel
                </Button>
                <Button onClick={toggleDetails} appearance="destructive">
                  Details
                </Button>
              </>
            ) : (
              <>
                <SubmitButton
                  disabled={counter === 0 || isEstimating || isSubmitting || !isNetworkSupported || isLoadingData}
                  loading={isEstimating || isSubmitting || isLoadingData}
                >
                  Submit
                </SubmitButton>

                <Button
                  onClick={isSubmitting ? abort : cancel}
                  disabled={isSubmitting && !isAbortable}
                  appearance="secondary"
                >
                  {isSubmitting ? "Abort" : "Cancel"}
                </Button>
              </>
            )}

            {debug && txData ? (
              <TenderlyTransactionLink
                from={accountAddress}
                gasLimit={gasLimit}
                gasPrice={priorityFee + baseFee}
                txData={txData}
              >
                {isError ? "Debug" : "Simulate"}
              </TenderlyTransactionLink>
            ) : null}
          </Modal.Actions>
        </>
      )}
    </Form>
  );
}
