import { AssetType } from "@enzymefinance/environment";
import { BigIntDisplay } from "@enzymefinance/ethereum-ui";
import { Integrations } from "@enzymefinance/sdk/Portfolio";
import { Assertion } from "@enzymefinance/sdk/Utils";
import { Card } from "@enzymefinance/ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import { useNetwork } from "components/providers/NetworkProvider";
import { useEModeCategoriesQuery } from "queries/backend";
import { useMemo } from "react";
import { client } from "utils/backend";
import { maxUint256 } from "viem";
import { getDefaultExtensionSummary } from "..";
import type { CreateExternalPositionHandler, ExternalPositionHandler } from "./types";
import { decodeCallOnExternalPositionArgs } from "./utils";

export const aaveV3AddCollateral: ExternalPositionHandler<Integrations.AaveV3.AddCollateralArgs> = {
  Description({
    args: {
      aTokens: [aTokenAddress],
      amounts: [amount],
      fromUnderlying,
    },
  }) {
    const { environment } = useGlobals();

    // these arrays will currently only ever be one item long
    Assertion.invariant(aTokenAddress !== undefined, "aTokenAddress cannot be undefined");
    Assertion.invariant(amount !== undefined, "amount cannot be undefined");

    const aToken = environment.getAssetAs(aTokenAddress, AssetType.AAVE_V3);
    const asset = fromUnderlying ? environment.getAsset(aToken.underlying) : aToken;

    return (
      <>
        Adding <BigIntDisplay value={amount} decimals={asset.decimals} numberFormat={{ currency: asset.symbol }} /> as
        collateral on Aave V3
      </>
    );
  },
  Label() {
    return <>Add Collateral on Aave V3</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.AaveV3.addCollateralDecode(encodedCallArgs),
};

export const aaveV3Borrow: ExternalPositionHandler<Integrations.AaveV3.BorrowArgs> = {
  Description({
    args: {
      underlyingTokens: [underlyingAddress],
      amounts: [amount],
    },
  }) {
    const { environment } = useGlobals();

    Assertion.invariant(underlyingAddress !== undefined, "underlyingAddress cannot be undefined");
    Assertion.invariant(amount !== undefined, "amount cannot be undefined");

    const asset = environment.getAsset(underlyingAddress);

    return (
      <>
        Borrowing <BigIntDisplay value={amount} decimals={asset.decimals} numberFormat={{ currency: asset.symbol }} />{" "}
        on Aave V3
      </>
    );
  },
  Label() {
    return <>Borrow on Aave V3</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.AaveV3.borrowDecode(encodedCallArgs),
};

export const aaveV3RepayBorrow: ExternalPositionHandler<Integrations.AaveV3.RepayBorrowArgs> = {
  Description({
    args: {
      underlyingTokens: [underlyingAddress],
      amounts: [amount],
    },
  }) {
    const { environment } = useGlobals();

    Assertion.invariant(underlyingAddress !== undefined, "underlyingAddress cannot be undefined");
    Assertion.invariant(amount !== undefined, "amount cannot be undefined");

    // these arrays will currently only ever be one item long
    const asset = environment.getAsset(underlyingAddress);
    const isRepayingAll = maxUint256 === amount;

    return (
      <>
        Repaying{" "}
        {isRepayingAll ? (
          "all"
        ) : (
          <BigIntDisplay value={amount} decimals={asset.decimals} numberFormat={{ currency: asset.symbol }} />
        )}{" "}
        owed to Aave V3
      </>
    );
  },
  Label() {
    return <>Repay Debt on Aave V3</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.AaveV3.repayBorrowDecode(encodedCallArgs),
};

export const aaveV3RemoveCollateral: ExternalPositionHandler<Integrations.AaveV3.RemoveCollateralArgs> = {
  Description({
    args: {
      aTokens: [aTokenAddress],
      amounts: [amount],
      toUnderlying,
    },
  }) {
    const { environment } = useGlobals();

    // these arrays will currently only ever be one item long
    Assertion.invariant(aTokenAddress !== undefined, "aTokenAddress cannot be undefined");
    Assertion.invariant(amount !== undefined, "amount cannot be undefined");

    const aToken = environment.getAssetAs(aTokenAddress, AssetType.AAVE_V3);
    const asset = toUnderlying ? environment.getAsset(aToken.underlying) : aToken;

    const isWithdrawingAll = maxUint256 === amount;

    return (
      <>
        Removing{" "}
        {isWithdrawingAll ? (
          `all ${asset.symbol}`
        ) : (
          <BigIntDisplay value={amount} decimals={asset.decimals} numberFormat={{ currency: asset.symbol }} />
        )}{" "}
        as collateral on Aave V3
      </>
    );
  },
  Label() {
    return <>Remove Collateral on Aave V3</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.AaveV3.removeCollateralDecode(encodedCallArgs),
};

export const aaveV3SetEMode: ExternalPositionHandler<Integrations.AaveV3.SetEModeArgs> = {
  Description({ args: { categoryId } }) {
    const { deployment, environment } = useNetwork();

    const eModeCategoriesQuery = useEModeCategoriesQuery({ client, variables: { network: deployment } });

    const categoryAssetSymbols = useMemo(() => {
      const eModeCategories = eModeCategoriesQuery.data?.eModeCategories;
      if (categoryId === 0) {
        return ["All assets"];
      }

      return (
        eModeCategories
          ?.find((_category) => _category.id === categoryId)
          ?.assets.map((asset) => environment.getAsset(asset)?.symbol) ?? []
      );
    }, [eModeCategoriesQuery, categoryId]);

    if (eModeCategoriesQuery.loading) {
      return null;
    }

    const disableEMode = categoryId === 0;

    return (
      <div className="space-y-2">
        {disableEMode ? (
          <p>
            You are disabling E-Mode. This action will reduce your health factor. Please be mindful of the increased
            risk of collateral liquidation.
          </p>
        ) : null}
        <Card appearance="secondary">
          <Card.Content>
            <div className="flex justify-between text-regular">
              <span>Available assets</span>
              <span className="text-high-emphasis">{categoryAssetSymbols.join(", ")}</span>
            </div>
          </Card.Content>
        </Card>
      </div>
    );
  },
  Label({ args: { categoryId } }) {
    const { deployment } = useNetwork();

    const eModeCategoriesQuery = useEModeCategoriesQuery({ client, variables: { network: deployment } });

    const selectedCategory = eModeCategoriesQuery.data?.eModeCategories.find((category) => category.id === categoryId);

    return <>{categoryId === 0 ? "Disable E-Mode" : `Switch EMode Category to ${selectedCategory?.label}`}</>;
  },
  decodeExternalPositionArgs: (encodedCallArgs) => Integrations.AaveV3.setEModeDecode(encodedCallArgs),
};

export const createAaveV3ExternalPosition: CreateExternalPositionHandler = {
  Description({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Aave V3 external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = aaveV3AddCollateral.decodeExternalPositionArgs(actionArgs);

    return <aaveV3AddCollateral.Description args={args} />;
  },
  Label({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Aave V2 external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = aaveV3AddCollateral.decodeExternalPositionArgs(actionArgs);

    return <aaveV3AddCollateral.Label args={args} />;
  },
  Summary({ callOnExternalPositionData }) {
    if (callOnExternalPositionData === "0x") {
      return <>Initialize Aave V2 external position</>;
    }

    const { actionArgs } = decodeCallOnExternalPositionArgs(callOnExternalPositionData);
    const args = aaveV3AddCollateral.decodeExternalPositionArgs(actionArgs);

    const Summary = getDefaultExtensionSummary(aaveV3AddCollateral.Label, aaveV3AddCollateral.Description);

    return <Summary args={args} />;
  },
};
