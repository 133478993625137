import { toAddress } from "@enzymefinance/environment";
import { Address as AddressComp } from "@enzymefinance/ethereum-ui";
import { Utils } from "@enzymefinance/sdk";
import { Assertion } from "@enzymefinance/sdk/Utils";
import { DurationDisplay, Skeleton } from "@enzymefinance/ui";
import { useVaultDetailsFromComptrollerQuery } from "queries/core";
import type { FunctionConfig } from "utils/functionsTypes";
import {
  type Address,
  type Hex,
  decodeAbiParameters,
  isAddressEqual,
  parseAbiParameters,
  toFunctionSelector,
} from "viem";
import { useGlobals } from "../../providers/GlobalsProvider";
import { mintSelector } from "../../vault/protocols/curve/utils.js";
import { delegateSelector } from "../../vault/protocols/delegateVotes/utils";

const aaveV2ClaimRewardsToSelfSelector = toFunctionSelector("function claimRewardsToSelf(address[], uint256)");
const addressListRegistryAddToListSelector = toFunctionSelector("function addToList(uint256,address[])");
const addressListRegistryRemoveFromListSelector = toFunctionSelector("function removeFromList(uint256,address[])");
const curveMinterMintManySelector = toFunctionSelector("function mint_many(address[8])");
const curveMinterToggleApproveMintSelector = toFunctionSelector("function toggle_approve_mint(address)");
const synthetixAssignExchangeDelegateSelector = toFunctionSelector("function approveExchangeOnBehalf(address)");
const startAssetByPassSelector = toFunctionSelector("function startAssetBypassTimelock(address)");
const pendleV2UpdateMarketsForCallerSelector = toFunctionSelector(
  "function updateMarketsForCaller((address,uint32)[] calldata _updateMarketInputs, bool _skipValidation)",
);

export const vaultCallOnContract: FunctionConfig<{ contract: Address; selector: Hex; encodedArgs: Hex }> = {
  signature: "function vaultCallOnContract(address contract, bytes4 selector, bytes encodedArgs)",

  Description({ address, inputs }) {
    const query = useVaultDetailsFromComptrollerQuery({
      variables: { id: address.toLowerCase() },
    });
    const { environment } = useGlobals();

    const vault = query.data?.comptroller?.vault;

    if (query.loading) {
      return <Skeleton className="h-4 w-full" />;
    }

    if (inputs.selector === synthetixAssignExchangeDelegateSelector) {
      return <>Authorize Synthetix trading for {vault?.name ?? "this vault"}.</>;
    }

    if (inputs.selector === addressListRegistryAddToListSelector) {
      return <>Add items to list for {vault?.name ?? "this vault"}.</>;
    }

    if (inputs.selector === addressListRegistryRemoveFromListSelector) {
      return <>Remove items from list for {vault?.name ?? "this vault"}.</>;
    }

    if (inputs.selector === aaveV2ClaimRewardsToSelfSelector) {
      return <>Claiming Aave V2 rewards for {vault?.name ?? "this vault"}.</>;
    }

    if (
      inputs.selector === curveMinterMintManySelector &&
      isAddressEqual(toAddress(inputs.contract), environment.externalContracts.curveMinter)
    ) {
      return <>Claiming Curve rewards for {vault?.name ?? "this vault"}.</>;
    }

    if (
      inputs.selector === curveMinterToggleApproveMintSelector &&
      isAddressEqual(toAddress(inputs.contract), environment.externalContracts.curveMinter)
    ) {
      return <>Unlock Curve minting for {vault?.name ?? "this vault"}</>;
    }

    if (
      inputs.selector === curveMinterMintManySelector &&
      isAddressEqual(toAddress(inputs.contract), environment.externalContracts.balancerMinter)
    ) {
      return <>Claiming Balancer rewards for {vault?.name ?? "this vault"}.</>;
    }

    if (
      inputs.selector === mintSelector &&
      isAddressEqual(toAddress(inputs.contract), environment.externalContracts.curveChildLiquidityGaugeFactory)
    ) {
      const [gaugeAddress] = decodeAbiParameters(parseAbiParameters("address"), inputs.encodedArgs);

      const gauge = environment.getAsset(gaugeAddress);

      return (
        <div>
          <span>Claim Rewards minting for</span> {gauge.symbol} <span>for {vault?.name ?? "this vault"}</span>
        </div>
      );
    }

    if (
      inputs.selector === curveMinterToggleApproveMintSelector &&
      isAddressEqual(toAddress(inputs.contract), environment.externalContracts.balancerMinter)
    ) {
      return <>Unlock Balancer minting for {vault?.name ?? "this vault"}</>;
    }

    if (inputs.selector === startAssetByPassSelector) {
      const [assetAddress] = decodeAbiParameters(parseAbiParameters("address"), inputs.encodedArgs);
      const asset = environment.getAsset(assetAddress);

      return (
        <>
          Allow asset {asset.symbol} to bypass Cumulative Slippage Policy for {vault?.name ?? "this vault"}.
        </>
      );
    }

    if (inputs.selector === delegateSelector) {
      const asset = environment.getAsset(inputs.contract);

      const [delegatee] = decodeAbiParameters(parseAbiParameters("address"), inputs.encodedArgs);

      if (Utils.Address.safeSameAddress(delegatee, vault?.id)) {
        return <>Undelegate {asset.symbol} votes</>;
      }

      return (
        <>
          Delegate {asset.symbol} votes from {vault?.name ?? "this vault"} to {delegatee}
        </>
      );
    }

    if (inputs.selector === pendleV2UpdateMarketsForCallerSelector) {
      const [[updateMarketInput]] = decodeAbiParameters(
        parseAbiParameters("(address,uint32)[],bool"),
        inputs.encodedArgs,
      );

      Assertion.invariant(updateMarketInput !== undefined, "updateMarketInput is undefined");

      const [market, duration] = updateMarketInput;

      if (duration === 0) {
        return (
          <div className="space-y-2">
            <span className="text-high-emphasis text-lg">Remove market</span>
            <div className="flex flex-row justify-between">
              <span>Market</span>
              <AddressComp address={market} trimmed={true} className="text-high-emphasis" />
            </div>
            <div className="flex flex-row justify-between">
              <span>Vault</span> <span className="text-high-emphasis">{vault?.name ?? "this vault"}</span>
            </div>
          </div>
        );
      }

      return (
        <div className="space-y-2">
          <span className="text-high-emphasis text-lg">Set market</span>
          <div className="flex flex-row justify-between">
            <span>Market</span>
            <AddressComp address={market} trimmed={true} className="text-high-emphasis" />
          </div>
          <div className="flex flex-row justify-between">
            <span>TWAP duration</span> <DurationDisplay className="text-high-emphasis" seconds={duration} />
          </div>
          <div className="flex flex-row justify-between">
            <span>Vault</span> <span className="text-high-emphasis">{vault?.name ?? "this vault"}</span>
          </div>
        </div>
      );
    }

    return (
      <>
        Call contract {inputs.contract} for {vault?.name ?? "this vault"}.
      </>
    );
  },

  Label() {
    return <>Call On Contract</>;
  },
};
